.App {
  text-align: center;
  font-family: 'Ubuntu', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #24211e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.counter,
.progress-bar,
.status-box,
.right-panel {
  align-self: flex-start; /* Align these elements to the right */
}


.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  display:flexbox;
  border: 1px solid #333;
  align-items:end;
}

.progress {
  height: 3px;
  margin-top: 1px;
  /* align-self: center; */
  border-radius: 4px;
  background-color: #688b68;
  width: 1%;
  transition: width 1s linear;
}


button {
  /* background-color: #688b68; */
  font-family: 'Ubuntu', sans-serif;
  /* border:color-mix(in hsl shorter hue, color percentage, color percentage); */
  color: rgb(8, 8, 8);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  /* border-style: solid; */
}

button:hover {
  background-color: #45a049;
}

.input-container {
  margin-top: 20px;
}

.input-container input {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  /* width: 200px; */
  box-sizing: border-box; /* Ensures padding and border are included in element's total width */
}

.input-container button {
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #0056b3;
}

.app-container {
  position: relative;
  font-family: 'Ubuntu', sans-serif;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
}

.chat-bubble:hover {
  background-color: #a7a8a7;
}

.chat-bubble:hover::before {
  content: "Click to mark it done";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  transition-delay:0s;
  font-size: 12px;
  z-index: 1;
  opacity: 1;

}

.bubble {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  padding: 50px;
  animation: fade-out 1000;
}

.chat-bubble {
  position: relative;
  cursor: pointer;

  background-color: #ececec;
  color: #0c0c0c;
  font-size: 14px;
  padding: 12px;
  border-radius: 25px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
  position: relative;
  overflow: hidden;

}

.current-bubble {
  background-color: #ececec;
  color: #0c0c0c;
  font-size: 14px;
  padding: 14px;
  border-radius: 25px;
  max-width: 80%;
  word-wrap: break-word;
}


@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}


.header {
  position: absolute;
  top: 30px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row-reverse;

}

.status-box {
  border: 2px solid #333;
  /* width: fit-content; */
  padding: 5px;
  margin-top: 50px;
  text-align: end;
}

.timer {
  position: absolute;
  text-align: end;
  top: 20px;
  right: 10px;
  color: #333;
  width: auto;
}
.timer div {
  color: #333;
  margin-bottom: 10px;
}

.counter {
  font-size: 50px !important;

}

.recorded-times {
  margin-top: 10px;
  min-width: max-content;
  color: #333;
}
.title {
  /* position: absolute; */
  /* top: 5px; */
  /* padding: 5px; */
  /* left: 10px; */
  display: flex;
  /* align-items: right; */
  /* align-items: center; */
  /* gap: 10px; */
  /* flex-direction: row; */
}

.title-logo {
  position: absolute;
  top: 10px;
  padding: 5px;
  margin: 5px;
  left: 10px;
  align-self: start;
  gap: 10px;
  flex-direction: column;
}
.timer img {
  height: 40px; /* Adjust the height as needed */
  /* margin-right: 20px; */
}

.cursor {
  display: inline-block;
  width: 5px;
  height: 40px;
  background-color: black;
  margin-left: 5px;
  align-self: center;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.timer .h3 {
  min-width: "fit-content";
  text-align: "end";
  margin: "1px";
  border-color: "#333";
}

.wave-panel {
  position: absolute;
  bottom: 50px;
  right: 10px;
}

.buttons-bar {
  /* position: absolute; */
  /* top: 10px; */
  right: 10px;
  display: flex;
  align-items: center;
  display: grid;
  /* gap: 10px; */
  /* flex-direction: row-reverse; */
}

.recorded-times-container {
  max-height: 150px;
  overflow-y: auto;
}

.right-panel-record {
  /* New */
    max-height: 80vh;
    overflow-y: auto;
    /* work */
    /* position: absolute; */
    text-align: right;
    top: 350px;
    right: 20px;
  }


.right-panel {
/* New */
  max-height: 80vh;
  overflow-y: auto;
  /* work */
  position: absolute;
  text-align: right;
  bottom: 10px;
  right: 20px;
}

.color-list {
  display: flex;
  flex-wrap: wrap;
}

.color-picker-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000; /* Ensure it appears above other elements */
}


.wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255,0,0,0.5), rgba(255,154,0,0.5), rgba(208,222,33,0.5), rgba(79,220,74,0.5), rgba(63,218,216,0.5), rgba(47,201,226,0.5), rgba(28,127,238,0.5), rgba(95,21,242,0.5), rgba(186,12,248,0.5), rgba(251,7,217,0.5), rgba(255,0,0,0.5));
  background-size: 600% 600%;
  animation: wave 2s linear infinite;
  z-index: -1;
}

@keyframes wave {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}